import { Component, BaseComponent, Prop } from '@zento-lib/components';
import type { I18nMessage } from '@zento-lib/components/Base/types';

import type { IFooterPoweredBy } from './PoweredBy.d';

/**
 * Footer PoweredBy
 *
 * Renders information about the company that built the site
 */
@Component({})
export class FooterPoweredBy extends BaseComponent<IFooterPoweredBy, unknown> {
  @Prop({ type: [Object, String], default: '' })
  allRightsReserved?: I18nMessage;

  @Prop({ type: [Object, String], default: '' })
  poweredBy?: I18nMessage;

  /**
   * A link representing the website of the company that built the site
   */
  @Prop({ type: [Object, String], default: '' })
  poweredByLink?: I18nMessage;

  /**
   * A text representing the name of the company that built the site
   */
  @Prop({ type: [Object, String], default: '' })
  poweredByLinkLabel?: I18nMessage;

  render() {
    return (
      <span>
        {this.allRightsReserved ? <span>{this.getTranslation(this.allRightsReserved)}</span> : null}
        <span>•</span>
        <span>{this.getTranslation(this.poweredBy)}</span>
        <a href={this.getTranslation(this.poweredByLink)} target='_blank' rel='noopener'>
          {this.getTranslation(this.poweredByLinkLabel)}
        </a>
      </span>
    );
  }
}
