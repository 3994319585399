import { Component, BaseComponent, Prop } from '@zento-lib/components';
import type { I18nMessage } from '@zento-lib/components/Base/types';

import { CmsBlock } from '../../../molecule/CmsBlock/CmsBlock';
import { FooterPoweredBy } from '../PoweredBy/PoweredBy';
import { Link } from '../../Link/Link';

import type { IFooterAbsolute } from './Absolute.d';
import style from './style.scss';

/**
 * Footer absolute
 *
 * Renders only minimal information
 */
@Component({})
export class FooterAbsolute extends BaseComponent<IFooterAbsolute, unknown> {
  /**
   * A text representing copyright information
   */
  @Prop({ type: [Object, String], default: '' })
  copyright?: I18nMessage;

  @Prop({ type: [Object, String], default: '' })
  poweredBy?: I18nMessage;

  @Prop({ type: [Object, String], default: '' })
  allRightsReserved?: I18nMessage;

  /**
   * A link representing the website of the company that built the site
   */
  @Prop({ type: [Object, String], default: '' })
  poweredByLink?: I18nMessage;

  /**
   * A text representing the name of the company that built the site
   */
  @Prop({ type: [Object, String], default: '' })
  poweredByLinkLabel?: I18nMessage;

  /**
   * A text representing return to the main page
   */
  @Prop({ type: [Object, String], default: '' })
  returnToStore?: I18nMessage;

  render() {
    const config = this.extended.$config.zento;

    return (
      <div class={style.bottomFooter}>
        <div
          class={{
            [style.container]: true,
            [style.containerSmall]: config.footerConfig.showImg,
          }}>
          <div
            class={{
              [style.copyright]: true,
              [style.copyrightCenter]: !config.footerConfig.showImg && !config.footerConfig.socialLinksBottom,
            }}
            key='copyright'>
            <p>
              {this.returnToStore ? (
                <Link to={'/'}>{this.getTranslation(this.returnToStore)}</Link>
              ) : (
                [
                  <span key='copyright-label'>{this.getTranslation(this.copyright)}</span>,
                  <FooterPoweredBy
                    allRightsReserved={this.allRightsReserved}
                    poweredBy={this.poweredBy}
                    poweredByLink={this.poweredByLink}
                    poweredByLinkLabel={this.poweredByLinkLabel}
                    key='powered-by'
                  />,
                ]
              )}
            </p>
          </div>
          {config.footerConfig.showImg ? (
            <div class={style.logoFooter} key='footer-logo'>
              <CmsBlock identifier={config.footerConfig.imgCms} />
            </div>
          ) : config.footerConfig.socialLinksBottom ? (
            <div class={style.socialFooter}>
              <CmsBlock identifier={config.footerConfig.socialCmsBlockId} />
            </div>
          ) : null}
        </div>
        {config.footerConfig.bottomCms ? (
          <div class={style.bottomCms} key='footer-bottom'>
            <CmsBlock identifier={config.footerConfig.footerBottomCms} />
          </div>
        ) : null}
      </div>
    );
  }
}
